var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.price_change_house
    ? _c(
        "div",
        [
          _c("highcharts", {
            staticClass: "average-price-chart",
            attrs: { options: _vm.initOptions() }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }