









import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import currency from 'currency.js';
import { Chart } from 'highcharts-vue';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

// этот компонент работает только при динамическом импорте, иначе крашится в режиме ssr
@Component({
  name: 'AppPriceChangeChart',
  props: {
    price_change_house: Object as PropType<AveragePriceData>,
    price_change_area: Object as PropType<AveragePriceData>,
    current_price: Number,
  },
  components: {
    highcharts: Chart,
  },
})
export default class AppPriceChangeChart extends Vue {
  // props
  price_change_house: AveragePriceData;
  price_change_area: AveragePriceData;
  current_price: number;

  // methods
  initOptions() {
    // const datesHome = this.price_change_house.list.map((arr) => {
    //   return arr[1];
    // });
    const dates: any[] = [];
    // let lastMonth = (moment(new Date()).format('M') as unknown as number) - 1;
    // if (!lastMonth) {
    //   lastMonth = 12;
    // }
    const firstMonth = moment().subtract(12, 'months');
    dates.push(firstMonth.format('MMMM YYYY'));
    for (
      let n = 1;
      n < 12;
      n++
    ) {
      // dates.push(moment({ month: n - 1, day: 1, year: firstMonth.year() }).format('MMMM YYYY'));
      dates.push(firstMonth.add(1, 'month').format('MMMM YYYY'));
    }
    const pricesHome = this.mapPrices(this.price_change_house.list, dates);
    let pricesArea: any[] = [];
    if (this.price_change_area) {
      pricesArea = this.mapPrices(this.price_change_area.list, dates);
    }
    // pricesHome = pricesHome.slice(0, dates.length);
    // pricesArea = pricesArea.slice(0, dates.length);
    // console.log(pricesHome, pricesArea);
    // console.log('house', this.price_change_house.list);
    // console.log('area', this.price_change_area.list);
    let pricesTemplate = '';
    if (this.getPrices(pricesHome)) {
      pricesTemplate += `
        <div class="average-price-chart__subtitle">
          <p class="average-price-chart__price average-price-chart__price--home"> 
            ${currency(this.getPrices(pricesHome), { symbol: '', separator: ' ', precision: 0 }).format()} ₽ 
          </p>
          <p class="average-price-chart__description">Цена за м<sup>2</sup> в доме</p>
        </div>`;
    }
    if (this.getPrices(pricesArea)) {
      pricesTemplate += `
        <div class="average-price-chart__subtitle">
          <p class="average-price-chart__price">
            ${currency(this.getPrices(pricesArea), { symbol: '', separator: ' ', precision: 0 }).format()} ₽ 
          </p>
          <p class="average-price-chart__description">Цена за м<sup>2</sup> в районе</p>
        </div>`;
    }
    return {
      colors: [ '#4052F3', '#4E4E4E' ],
      title: {
        align: 'left',
        text: `
        <div class="average-price-chart__chart-head">
          <span class="average-price-chart__chart-title">График изменения цены за м2</span>
          <br>
          <div class="average-price-chart__subtitle-wrapper">
            ${pricesTemplate}
          </div>
        </div>`,
        useHTML: true,
      },
      chart: {
        type: 'line',
        backgroundColor: '#FFF',
      },
      xAxis: {
        categories: dates,
      },
      tooltip: {
        backgroundColor: '#3C3C3C',
        style: {
          color: '#fff',
        },
        borderRadius: 15,
      },
      yAxis: {
        title: {
          text: '',
        },
      },

      // plotOptions: {
      //   series: {
      //     fillColor: {
      //       linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      //       stops: [
      //         [ 0, '#FF7D45' ],
      //         [ 1, '#F8F2EF' ],
      //       ],
      //     },
      //   },
      // },

      series: [
        {
          name: 'В доме',
          data: pricesHome,
        },
        {
          name: 'В районе',
          data: pricesArea,
        },
      ],
    };
  }

  getAveragePrices(list: (string | number)[][]) {
    const pricesAll: any[] = [];
    let prices: any[] = [];
    list.forEach((arr) => {
      const momentDate = moment(arr[0]);
      const month = (momentDate.format('M') as unknown as number) - 1;
      if (!pricesAll[month]) {
        pricesAll[month] = [];
      }
      if (moment().year() === momentDate.year()) {
        pricesAll[month].push(arr[1]);
      }
    });
    pricesAll.forEach((elem, i) => {
      if (elem.length) {
        prices[i] = Math.round(
          elem.reduce((a: number, b: number) => a + b) / elem.length
        );
      }
    });
    prices = Array.from(prices, (item) => item || null);
    return prices;
  }

  mapPrices(list: any[], dates: any[]) {
    return dates.map((elem) => {
      const res = list.find((item) => {
        return elem === moment(item[0] as string).format('MMMM YYYY');
      });
      return res ? Math.round(res[1]) : null;
    });
  }

  getPrices(arr: any) {
    if (arr[arr.length - 1]) {
      return arr[arr.length - 1];
    } else if (arr[arr.length - 2]) {
      return arr[arr.length - 2];
    } else if (arr[arr.length - 3]) {
      return arr[arr.length - 3];
    }
    return 0;
  }
}
